export enum Operations {
    QUERY_BALANCE = 1,
    ISSUE_STATEMENT = 2,
    WITHDRAW = 3,
    DEPOSIT = 4,
    TRANSFER = 5,
    FEE_COLLECTION = 6,
    RETURN_RESERVED_BALANCE = 7,
    EXTERNAL_TRANSFER = 8,
     SETTLEMENT = 9,

    TRADING_ORDER_SELL = -1,
    TRADING_ORDER_BUY = -2,
    EXTERNAL_TRADING_ORDER_SELL_DEPOSIT = -3,
    EXTERNAL_TRADING_ORDER_SELL_WITHDRAW = -4,
    EXTERNAL_TRADING_ORDER_BUY_DEPOSIT = -5,
    EXTERNAL_TRADING_ORDER_BUY_WITHDRAW  = -6,
}

export enum AuthorizedOperation
{
    QUERY_BALANCE = 1,
    ISSUE_STATEMENT = 2,
    WITHDRAW = 3,
    DEPOSIT = 4,
    TRANSFER = 5,
    UPDATE_AUTHORIZATION_REQUIREMENT = 6,
    RESERVE_BALANCE = 7,
    FREE_BALANCE = 8,
    TRADING_ORDER_SELL = 9,
    TRADING_ORDER_BUY = 10,
    TRANSFER_OWNERSHIP = 11,
    EXTERNAL_TRANSFER = 12,
    TRIGGERED_TRANSFER = 13,
    ADD_ACCOUNT_AUTHORIZER = 14,
    DELETE_ACCOUNT_AUTHORIZER = 15,

}

export enum LogType
{
    SUCCESS_OP = 1,
    APP_EXCEPTION = 2,
    FATAL_EXCEPTION = 3
}

export enum CurrencyForm{
    FIAT = 1,
    CRYPTO = 2,
    COMMODITY = 3,
    DIGITAL = 4,
    REPRESENTATIVE = 5
}

export enum AccountStatus
{
    ACTIVE = 1,
    INACTIVE = 2,
    CLOSED = 3
}

export enum LimitationType
{
    TREASURY_LIMITATION = 1,
    ACCOUNT_LIMITATION = 2
}


export enum TreasuryStatus
{
    ACTIVE = 1,
    INACTIVE = 2,
    CLOSED = 3
}

export enum Natures
{
    PHYSICAL = 1,
    VIRTUAL = 2
}

export enum ReservationStatus
{
    RESERVED = 1,
    FREED = 2,
    RETURNED = 3,
    FORWARDED = 4,
    TRANSFERRED = 5,
    WITHDRAWN = 6,
    LOCKED = 7,
}

export enum OrderType
{
    MARKET_ORDER = 1,
    LIMIT_ORDER = 2, 
    LIMIT_ORDER_External = 3,
    ALL_OR_NONE_ORDER = 4

}

export enum OrderAction
{
    BUY = 1,
    SELL = 2
}

export enum OrderStatus
{
    NEW = 0,
    OPEN = 1,
    FILLD = 2,
    CANCELED = 3,
}

export enum AuthorizationStatus
{
    REQUEST = 0,
    NEW = 1,
    USED = 2,
    REVOKED = 3,
    EXPIRED = 4,
    DECLINED = 5,
}