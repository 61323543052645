import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest } from '../../../app/Api';
import { AuthenticationEndpointClient } from '../../../repository/dimensions/AuthenticationServiceClientPb';
import { AuthenticateRequest, AuthenticateResponse, TwoFactorConfirmationRequest, TwoFactorConfirmationResponse } from '../../../repository/dimensions/authentication_pb';

export interface LoginFormState {
  isLoading: boolean
  twoFactorConfirm: any
  twoFactorConfirmAttempts: number,
  message?: ApiMessage
}

const initialState: LoginFormState = {
  isLoading: false,
  twoFactorConfirm: undefined,
  twoFactorConfirmAttempts: 0,
  message: undefined
}

const client = new AuthenticationEndpointClient(API_URL, null, null);
export const login = createAsyncThunk<AuthenticateResponse.AsObject, APIRequest<AuthenticateRequest>, {

  rejectValue: ApiMessage
}>(
  'login',
  async (req, thunkApi) => {
    try {
      var r = await client.authenticate(req.body, req.headers ?? {});
      console.log(r.getAuthenticate())
      return r.toObject();
    } catch (err) {
      try {
        console.log(err)
        return thunkApi.rejectWithValue(toApiMessage((err as RpcError).metadata))
      } catch (err) {

        return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
      }
    }
  }

)


export const twoFactorConfirm = createAsyncThunk<TwoFactorConfirmationResponse.AsObject, APIRequest<TwoFactorConfirmationRequest>, {

  rejectValue: ApiMessage
}>(
  'twoFactorConfirmation',
  async (req, thunkApi) => {
    try {
      var r = await client.twoFactorConfirmation(req.body, req.headers ?? {});
      return r.toObject();
    } catch (err) {
      try {
        return thunkApi.rejectWithValue(toApiMessage((err as RpcError).metadata))
      } catch (err) {

        return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
      }
    }
  }

)

export const loginFormSlice = createSlice({
  name: 'forms/login',
  initialState,
  reducers: {

    dismissMessage: (state) => {
      state.message = undefined;
    },
    setLoading: (state, action: PayloadAction<any>) => {
      state.isLoading = action.payload;
    },
    reset: (state) => {
      state.isLoading = false;
      state.message = undefined;
      state.twoFactorConfirm = undefined;
      state.twoFactorConfirmAttempts = 0;
    },
    twoFactorConfirmation: (state, action: PayloadAction<any>) => {
      state.twoFactorConfirm = action.payload;
      state.twoFactorConfirmAttempts = 0;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      console.log(payload)
    })
    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.message = action.payload;
      }
    })
    builder.addCase(login.pending, (state, action) => {
      state.isLoading = true;
      state.message = undefined;
    })

    builder.addCase(twoFactorConfirm.fulfilled, (state, { payload }) => {
      state.isLoading = false;
    })
    builder.addCase(twoFactorConfirm.rejected, (state, action) => {
      state.isLoading = false;
      if (state.twoFactorConfirmAttempts >= state.twoFactorConfirm.maxattemptsnumber) {
        state.twoFactorConfirm = undefined;
        state.twoFactorConfirmAttempts = 0;
      }
      if (action.payload) {
        state.message = action.payload;
      }
    })
    builder.addCase(twoFactorConfirm.pending, (state, action) => {
      state.isLoading = true;
      state.message = undefined;
      state.twoFactorConfirmAttempts = state.twoFactorConfirmAttempts + 1;
    })


  }
})

export const { reset, dismissMessage, twoFactorConfirmation, setLoading } = loginFormSlice.actions

export default loginFormSlice.reducer


