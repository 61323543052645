import { ThemeProvider, initializeIcons } from "@fluentui/react"
import { BrowserRouter, Navigate, NavigateFunction, NavigateOptions, Route, Routes, useLocation } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "./app/Hooks"
import { getPage } from "./app/Pages"
import { AppTheme, getMainTheme, ThemeManager } from "./app/Themes"
import { Main } from "./components/layouts/Main/MainLayout"
import { useContext, useEffect } from "react"
import { NotFound } from "./components/common/NotFound/NotFound"
import { AdvancedChartPage } from "./components/pages/AdvancedChart/AdvancedChartPage"
import { AdvancedChartSanadPage } from "./components/pages/SanadChart/AdvancedChartSanadPage"


initializeIcons();

export const App: React.FunctionComponent = () => {
    const state: { appTheme: string, isDarkModeEnabled: boolean, currentLang: string } = useAppSelector((state) => {

        return { appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled, currentLang: state.settings.currentLang }
    })
    return (
        <ThemeProvider theme={getMainTheme(state.appTheme, state.isDarkModeEnabled)} style={{ height: '100%', width: '100%' }}  >
            <BrowserRouter>
                <Routes>
         
                    <Route path="/" element={<RequireAuth redirectTo="/chart"> <Main />  </RequireAuth>}>
                        <Route path='*' element={<NotFound />} />



                        

                    </Route>

                    
                    <Route path="/chart" element={<BypassAuth><Main /></BypassAuth>} />
                </Routes>
            </BrowserRouter>

        </ThemeProvider >

    );
}


const RequireAuth: React.FunctionComponent<{ redirectTo: string, children: React.ReactNode }> = (props) => {
    let isAuthenticated = true;
    return (isAuthenticated ? props?.children as React.ReactElement : <Navigate to={props.redirectTo} />);
}

const BypassAuth: React.FunctionComponent<{ children: React.ReactNode }> = (props) => {
    let isAuthenticated = true;
    return (isAuthenticated ? <Navigate to="/" /> : props?.children as React.ReactElement);
}
