import { IBreadcrumbItem } from '@fluentui/react';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppTheme, defaultTheme, ThemeManager } from '../../../app/Themes';

import { RpcError } from "grpc-web";
import { ApiMessage, APIRequest, APIResponse, API_URL, getSession, refreshSession, toApiMessage } from "../../../app/Api";
import { formatDate, timestampToDate } from "../../../app/Helpers";
import { AuthenticationEndpointClient } from "../../../repository/dimensions/AuthenticationServiceClientPb";
import { LogoutRequest, LogoutResponse } from "../../../repository/dimensions/authentication_pb";

export interface SettingsState {
  isVisible: boolean,
  appTheme: string,
  isDarkModeEnabled: boolean,
  currentLang: string
}

const initialState: SettingsState = {
  isVisible: false,
  appTheme: ThemeManager.getThemeManager().getTheme().themeId,
  isDarkModeEnabled: ThemeManager.getThemeManager().getTheme().isDarkModeEnabled,
  currentLang: (localStorage.getItem("lang") == "en") ? "en" : "ar"
}





export const navSettingsSlice = createSlice({
  name: 'navSettings',
  initialState,
  reducers: {
    isVisible: (state, action: PayloadAction<boolean>) => {
      state.isVisible = action.payload;
    },
    setAppTheme: (state, action: PayloadAction<string>) => {
      state.appTheme = action.payload;
    },
    isDarkModeEnabled: (state, action: PayloadAction<boolean>) => {
      state.isDarkModeEnabled = action.payload;
    },
    setLang: (state, action: PayloadAction<string>) => {
      state.currentLang = action.payload;
    }
  }
})

export const { isVisible, setAppTheme, isDarkModeEnabled, setLang } = navSettingsSlice.actions

export default navSettingsSlice.reducer


