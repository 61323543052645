import moment from "moment";
import { ReactInstance } from "react";
import { AuthorizedOperation, Operations, OrderAction, OrderStatus, OrderType } from "./Enums";
import i18n from "./i18n";


export const timestampToDate = (seconds: number | undefined, nanos: number | undefined): Date | undefined => {

    if (seconds) {

        const a = new Date(seconds * 1000);
        if (nanos)
            a.setMilliseconds((nanos) / 1e6)

        return a;
    }

}


export const formatDate = (date: Date | undefined, format = 'YYYY-MM-DD HH:mm:ss'): string => {

    return moment(date).format(format);

}

export const phoneNumberFormatter = (PhoneNumber: string): string | undefined => {
    PhoneNumber = PhoneNumber.trim();
    PhoneNumber = PhoneNumber.replace('\u0660', '0')
        .replace('\u0661', '1')
        .replace('\u0662', '2')
        .replace('\u0663', '3')
        .replace('\u0664', '4')
        .replace('\u0665', '5')
        .replace('\u0666', '6')
        .replace('\u0667', '7')
        .replace('\u0668', '8')
        .replace('\u0669', '9'); // ٠٩١٤٢٦٧٣١٥ cases
    if (PhoneNumber.length >= 8) {
        PhoneNumber = PhoneNumber.trim().replace(" ", "").replace("+", "").replace("-", ""); // Remove spaces, +, -
        if (PhoneNumber.length < 4)
            return undefined;
        let isAllDigits = /^\d+$/.test(PhoneNumber);
        if (!isAllDigits)
            return undefined;
        if (PhoneNumber.length > 15)
            return undefined;

        if (PhoneNumber.startsWith("00")) {
            PhoneNumber = PhoneNumber.substring(2); // 00218912129959 => 218912129959
        }
        else if (PhoneNumber.startsWith("09")) // Local case
        {
            PhoneNumber = "218" + PhoneNumber.substring(1); // 0912129599 => 218912129959
        }
        return PhoneNumber;
    }
    return undefined;

}

export const getCurrentLanguage = (): string => {

    var lang = i18n.language || localStorage.getItem("lang") || 'ar'
    return lang;

}

export const normalizeKey = (key: string) => key as unknown as TemplateStringsArray;

export const currencyFormatter = (value: number, options: any) => {


    options = {
        ...{
            thousandsSeparator: ',',
            decimalSeparator: '.',
        }, ...options
    }
    let num = Number(value);
    if (isNaN(num)) {
        return ''
    }
    let minus = ""
    if (num < 0) {
        num = num * -1;
        minus = "-"
    }
    let v = options.significantDigits == undefined ? num + "" : num.toFixed(options.significantDigits)


    const [currency, decimal] = v.split('.')
    if (options.symbol == undefined) {
        if (options.significantDigits == 0) {
            return `${currency.replace(
                /\B(?=(\d{3})+(?!\d))/g,

                options.thousandsSeparator
            )}${minus}`
        } else {
            return `${currency.replace(
                /\B(?=(\d{3})+(?!\d))/g,

                options.thousandsSeparator
            )}${options.decimalSeparator}${decimal ?? "0"}${minus}`
        }


    }
    if (isRTL(options.symbol)) {
        if (options.significantDigits == 0) {
            return `${currency.replace(
                /\B(?=(\d{3})+(?!\d))/g,

                options.thousandsSeparator
            )}${minus} ${options.symbol}`
        } else {
            return `${currency.replace(
                /\B(?=(\d{3})+(?!\d))/g,

                options.thousandsSeparator
            )}${options.decimalSeparator}${decimal ?? "0"}${minus} ${options.symbol}`
        }

    }
    if (options.significantDigits == 0) {
        return `${options.symbol}${minus}${currency.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            options.thousandsSeparator
        )}`
    } else {
        return `${options.symbol}${minus}${currency.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            options.thousandsSeparator
        )}${options.decimalSeparator}${decimal ?? "0"}`
    }


}

export const isRTL = (text: string): boolean => {

    let regexp = new RegExp('[\u0600-\u06FF]+')
    return regexp.test(text);

}


export const printComponent = (html?: any, isTable: boolean = false): ReactInstance => {

    const PrintElem = document.createElement('div');
    PrintElem.className = isTable ? "table_printing_content" : "printing_content"
    const header =
        `<br><b>${formatDate(new Date())}</b>` +
        `<br><b>${window.location.href}</b>`
    PrintElem.innerHTML = header;
    PrintElem.appendChild(html);
    return PrintElem
}




export const base64ToArrayBuffer = (e: string) => {
    var binary_string = window.atob(e);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

export const downloadFile = (base64Data: string, fileName: string) => {
    var data = new Blob([base64ToArrayBuffer(base64Data)]);
    var csvURL = window.URL.createObjectURL(data);
    var tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', fileName);
    tempLink.click();

}

export const getFileExtension = (type: number): string => {
    return type == 1 ? ".xlsx" : type == 2 ? ".csv" : type == 3 ? ".pdf" : ".txt";
}

