import { IconButton, IStackTokens, Separator, Image, Link, MessageBar, MessageBarType, Modal, PrimaryButton, Spinner, SpinnerSize, Stack, Text, TextField } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/Hooks";
import { dismissMessage, login, reset, setLoading, twoFactorConfirm, twoFactorConfirmation } from "./LoginFormSlice";
import * as Yup from 'yup';
import { InputField, NumberInputField } from "../controls/Controls";
import { inputs } from "../../../app/Validation";

import { AuthenticateRequest, AuthenticateResponse, TwoFactorConfirmationRequest, TwoFactorConfirmationResponse } from "../../../repository/dimensions/authentication_pb";
import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, saveSession } from "../../../app/Api";
import { Message } from "../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps } from "../FormProps";
import { reCaptchaKey } from "../../../app/Content";
import ReCAPTCHA from "react-google-recaptcha";
import { getCurrentLanguage, phoneNumberFormatter } from "../../../app/Helpers";

let loginPromise: any;
let nextURL: string = "/";

export const LoginForm: React.FunctionComponent<BaseFormProps & IStackTokens> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const state: { isLoading: boolean, message: ApiMessage | undefined, twoFactorConfirmation: any } = useAppSelector((state) => {

        return { isLoading: state.loginForm.isLoading, message: state.loginForm.message, twoFactorConfirmation: state.loginForm.twoFactorConfirm }
    })

    const reCaptchaRef = useRef<any>();

    useEffect(() => {
        var l = searchParams.get('return')
        if (l) {
            nextURL = l;
        }
        return () => { //clean up
            loginPromise?.abort();
            dispatch(reset());
        }
    }, [])


    return (
        !state.twoFactorConfirmation ? <>
            <ReCAPTCHA
                sitekey={reCaptchaKey}
                size="invisible"
                hl={getCurrentLanguage()}
                ref={reCaptchaRef}

            />

            <Formik


                initialValues={{

                    username: '',

                    password: '',

                }}

                validationSchema={Yup.object({

                    username: inputs.primaryPhoneNumber,

                    password: inputs.password
                })}

                onSubmit={async (values, actions) => {
                    dispatch(setLoading(true))
                    let token = undefined;
                    try {
                        token = await reCaptchaRef.current.executeAsync();
                        if (token == undefined) {
                            dispatch(setLoading(false))
                            actions.setSubmitting(false)
                            return;
                        }
                    } catch {
                        dispatch(setLoading(false))
                        actions.setSubmitting(false)
                        return;
                    }
                    clearSession();
                    const req = new AuthenticateRequest();
                    let phone = phoneNumberFormatter(values.username)
                    req.setUsername(phone as string);
                    req.setPassword(values.password);
                    req.setCaptchatoken(token);
                    loginPromise = dispatch(login({ body: req, headers: getHeaders() }))
                    loginPromise.unwrap()
                        .then((res: AuthenticateResponse.AsObject) => {


                            actions.resetForm({ values: { ...values, password: "" } }) // dose not trigger validation

                            if (res.authenticate) {
                                saveSession(res.authenticate)
                                navigate(nextURL);
                                if (props.onSuccess)
                                    props.onSuccess(res.authenticate)

                            }
                            if (res.twofactorenabled) {
                                dispatch(twoFactorConfirmation({ ...res.twofactorenabled }))
                            }
                            actions.setSubmitting(false)

                        })
                        .catch((error: ApiMessage) => {
                            console.log(error)

                            actions.setSubmitting(false)
                            actions.setFieldValue("password", "")

                        })

                    reCaptchaRef.current.reset();

                }}

            >

                <Form>
                    <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                        {state.message != undefined ? <Message
                            body={state.message.body}
                            title={state.message.title}
                            data={state.message.data}
                            onDismiss={() => { dispatch(dismissMessage()) }}
                            type={state.message.type}
                        />
                            : null
                        }
                        <Field name="username" dir="ltr" autocomplete="on" label={t("phonenumber")} placeholder={"09XXXXXXXX"} component={InputField} disabled={state.isLoading} />
                        <Field name="password" label={t("password")}
                            type="password"
                            canRevealPassword
                            disabled={state.isLoading}
                            revealPasswordAriaLabel={t("showPassword")} placeholder={t("password")} component={InputField} />


                        <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : t("signin")} type="submit" >

                            <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                        </PrimaryButton>


                        <Separator alignContent="start"><b>{t("noAccount")}</b></Separator>


                        <Stack horizontalAlign="center" horizontal>

                            <Link styles={{ root: { width: "150px" } }} target="_blank" href='https://play.google.com/store/apps/details?id=ly.devpoint.sanad&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                <Image styles={{ image: { width: "150px" } }} alt='احصل عليه من Google Play' src={'https://play.google.com/intl/en_us/badges/static/images/badges/' + (getCurrentLanguage()) + '_badge_web_generic.png'} />
                            </Link>
                            <Link styles={{ root: { width: "120px", margin: 8 } }} target="_blank" href="https://apps.apple.com/ly/app/sanad-%D8%B3-%D9%86%D8%AF/id6460253495?itsct=apps_box_badge&amp;itscg=30200">

                                <Image styles={{ image: { width: "120px" } }} src={"https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/" + (getCurrentLanguage() == "ar" ? "ar-ar" : "en-us") + "?size=250x83&amp;releaseDate=1692921600"} alt="Download on the App Store" /></Link>
                        </Stack>

                    </Stack>
                </Form>
            </Formik>
        </> :
            <>


                <Formik
                    initialValues={{ phoneCode: '', emailCode: '' }}


                    validationSchema={state.twoFactorConfirmation.accountemail?.value && state.twoFactorConfirmation.accountphonenumber?.value ? Yup.object({
                        phoneCode: inputs.code,
                        emailCode: inputs.code,

                    }) : (state.twoFactorConfirmation.accountemail?.value ? Yup.object({
                        emailCode: inputs.code,


                    }) : Yup.object({
                        phoneCode: inputs.code,


                    }))}

                    onSubmit={(values, actions) => {
                        clearSession();
                        const req = new TwoFactorConfirmationRequest();
                        if (state.twoFactorConfirmation.accountemail?.value && state.twoFactorConfirmation.accountphonenumber?.value) {
                            console.log(111)
                            req.setCode(values.phoneCode + "");
                            req.setSecondcode(values.emailCode + "");
                        } else if (state.twoFactorConfirmation.accountemail?.value) {
                            req.setCode(values.emailCode + "");
                        } else {
                            req.setCode(values.phoneCode + "");
                        }
                        req.setCodeid(state.twoFactorConfirmation.codeid);
                        req.setSignature(state.twoFactorConfirmation.signature?.value);

                        loginPromise = dispatch(twoFactorConfirm({ body: req, headers: getHeaders() }))
                        loginPromise.unwrap()
                            .then((res: TwoFactorConfirmationResponse.AsObject) => {
                                actions.resetForm({ values: { ...values, emailCode: "", phoneCode: "" } }) // dose not trigger validation
                                if (res.authenticate) {
                                    saveSession(res.authenticate)
                                    if (props.onSuccess)
                                        props.onSuccess(res.authenticate)

                                    navigate(nextURL);
                                }

                                actions.setSubmitting(false)


                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                                //actions.setFieldValue("code", "")

                            })



                    }}

                >

                    <Form>
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            />
                                : null
                            }
                            <Text block>{t("twoFactorCodeHint")}</Text>
                            <ul>
                                {state.twoFactorConfirmation.accountemail?.value ? <li><b>{state.twoFactorConfirmation.accountemail?.value}</b></li> : null}
                                {state.twoFactorConfirmation.accountphonenumber?.value ? <li><b>{state.twoFactorConfirmation.accountphonenumber?.value}</b></li> : null}
                            </ul>

                            {state.twoFactorConfirmation.accountphonenumber?.value ? <Field name={`phoneCode`}
                                label={t("phoneCode")} component={InputField}
                                disabled={state.isLoading} maxLength={6} required />
                                : undefined}

                            {state.twoFactorConfirmation.accountemail?.value ? <Field name={`emailCode`}
                                label={t("emailCode")} component={InputField}
                                disabled={state.isLoading} maxLength={6} required />
                                : undefined}

                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : t("confirm")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                        </Stack>
                    </Form>
                </Formik>
            </>
    );

}

