// source: currency_pair.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('./common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.currency_pair_endpoint.Category', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.CurrencyPair', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCategoriesReplay', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCategoriesRequest', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCategoriesResponse', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCategoriesResponse.ResultCase', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetChartReplay', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetChartRequest', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetChartResponse', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetChartResponse.ResultCase', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCurrencyPairReplay', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCurrencyPairRequest', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCurrencyPairResponse', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCurrencyPairResponse.ResultCase', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCurrencyPairsReplay', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCurrencyPairsRequest', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCurrencyPairsResponse', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.GetCurrencyPairsResponse.ResultCase', null, global);
goog.exportSymbol('proto.currency_pair_endpoint.PricePeriod', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.GetCurrencyPairRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.GetCurrencyPairRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.GetCurrencyPairRequest.displayName = 'proto.currency_pair_endpoint.GetCurrencyPairRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.GetChartRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.GetChartRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.GetChartRequest.displayName = 'proto.currency_pair_endpoint.GetChartRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.GetCurrencyPairsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.GetCurrencyPairsRequest.displayName = 'proto.currency_pair_endpoint.GetCurrencyPairsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.GetCategoriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.GetCategoriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.GetCategoriesRequest.displayName = 'proto.currency_pair_endpoint.GetCategoriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.PricePeriod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.PricePeriod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.PricePeriod.displayName = 'proto.currency_pair_endpoint.PricePeriod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.GetChartReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.currency_pair_endpoint.GetChartReplay.repeatedFields_, null);
};
goog.inherits(proto.currency_pair_endpoint.GetChartReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.GetChartReplay.displayName = 'proto.currency_pair_endpoint.GetChartReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.GetCurrencyPairReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.GetCurrencyPairReplay.displayName = 'proto.currency_pair_endpoint.GetCurrencyPairReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.CurrencyPair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.CurrencyPair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.CurrencyPair.displayName = 'proto.currency_pair_endpoint.CurrencyPair';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.Category = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.currency_pair_endpoint.Category, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.Category.displayName = 'proto.currency_pair_endpoint.Category';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.GetCurrencyPairsReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.currency_pair_endpoint.GetCurrencyPairsReplay.repeatedFields_, null);
};
goog.inherits(proto.currency_pair_endpoint.GetCurrencyPairsReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.GetCurrencyPairsReplay.displayName = 'proto.currency_pair_endpoint.GetCurrencyPairsReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.GetCategoriesReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.currency_pair_endpoint.GetCategoriesReplay.repeatedFields_, null);
};
goog.inherits(proto.currency_pair_endpoint.GetCategoriesReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.GetCategoriesReplay.displayName = 'proto.currency_pair_endpoint.GetCategoriesReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.currency_pair_endpoint.GetCurrencyPairsResponse.oneofGroups_);
};
goog.inherits(proto.currency_pair_endpoint.GetCurrencyPairsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.GetCurrencyPairsResponse.displayName = 'proto.currency_pair_endpoint.GetCurrencyPairsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.currency_pair_endpoint.GetCurrencyPairResponse.oneofGroups_);
};
goog.inherits(proto.currency_pair_endpoint.GetCurrencyPairResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.GetCurrencyPairResponse.displayName = 'proto.currency_pair_endpoint.GetCurrencyPairResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.GetCategoriesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.currency_pair_endpoint.GetCategoriesResponse.oneofGroups_);
};
goog.inherits(proto.currency_pair_endpoint.GetCategoriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.GetCategoriesResponse.displayName = 'proto.currency_pair_endpoint.GetCategoriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.currency_pair_endpoint.GetChartResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.currency_pair_endpoint.GetChartResponse.oneofGroups_);
};
goog.inherits(proto.currency_pair_endpoint.GetChartResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.currency_pair_endpoint.GetChartResponse.displayName = 'proto.currency_pair_endpoint.GetChartResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.GetCurrencyPairRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.GetCurrencyPairRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairRequest}
 */
proto.currency_pair_endpoint.GetCurrencyPairRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.GetCurrencyPairRequest;
  return proto.currency_pair_endpoint.GetCurrencyPairRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairRequest}
 */
proto.currency_pair_endpoint.GetCurrencyPairRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.GetCurrencyPairRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.GetCurrencyPairRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.currency_pair_endpoint.GetCurrencyPairRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.GetChartRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.GetChartRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.GetChartRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetChartRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    currencypair: jspb.Message.getFieldWithDefault(msg, 1, ""),
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    groupby: (f = msg.getGroupby()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.GetChartRequest}
 */
proto.currency_pair_endpoint.GetChartRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.GetChartRequest;
  return proto.currency_pair_endpoint.GetChartRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.GetChartRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.GetChartRequest}
 */
proto.currency_pair_endpoint.GetChartRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencypair(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setGroupby(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.GetChartRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.GetChartRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.GetChartRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetChartRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencypair();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGroupby();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional string currencyPair = 1;
 * @return {string}
 */
proto.currency_pair_endpoint.GetChartRequest.prototype.getCurrencypair = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.GetChartRequest} returns this
 */
proto.currency_pair_endpoint.GetChartRequest.prototype.setCurrencypair = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp from = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.GetChartRequest.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.GetChartRequest} returns this
*/
proto.currency_pair_endpoint.GetChartRequest.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetChartRequest} returns this
 */
proto.currency_pair_endpoint.GetChartRequest.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetChartRequest.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp to = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.GetChartRequest.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.GetChartRequest} returns this
*/
proto.currency_pair_endpoint.GetChartRequest.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetChartRequest} returns this
 */
proto.currency_pair_endpoint.GetChartRequest.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetChartRequest.prototype.hasTo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value groupBy = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.currency_pair_endpoint.GetChartRequest.prototype.getGroupby = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.currency_pair_endpoint.GetChartRequest} returns this
*/
proto.currency_pair_endpoint.GetChartRequest.prototype.setGroupby = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetChartRequest} returns this
 */
proto.currency_pair_endpoint.GetChartRequest.prototype.clearGroupby = function() {
  return this.setGroupby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetChartRequest.prototype.hasGroupby = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.GetCurrencyPairsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    base: (f = msg.getBase()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    quote: (f = msg.getQuote()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    category: (f = msg.getCategory()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    nextto: (f = msg.getNextto()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    numofresults: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.GetCurrencyPairsRequest;
  return proto.currency_pair_endpoint.GetCurrencyPairsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setQuote(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCategory(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextto(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.GetCurrencyPairsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getQuote();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCategory();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getNextto();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional google.protobuf.StringValue base = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.getBase = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.hasBase = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue quote = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.getQuote = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.setQuote = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.clearQuote = function() {
  return this.setQuote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.hasQuote = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue category = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.getCategory = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.setCategory = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.clearCategory = function() {
  return this.setCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.hasCategory = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue search = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool order = 6;
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional google.protobuf.StringValue nextTo = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.getNextto = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.setNextto = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.clearNextto = function() {
  return this.setNextto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.hasNextto = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 numOfResults = 8;
 * @return {number}
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsRequest} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairsRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.GetCategoriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.GetCategoriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.GetCategoriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCategoriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    nextto: (f = msg.getNextto()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    numofresults: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.GetCategoriesRequest}
 */
proto.currency_pair_endpoint.GetCategoriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.GetCategoriesRequest;
  return proto.currency_pair_endpoint.GetCategoriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.GetCategoriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.GetCategoriesRequest}
 */
proto.currency_pair_endpoint.GetCategoriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextto(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.GetCategoriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.GetCategoriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.GetCategoriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCategoriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getNextto();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional google.protobuf.StringValue search = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCategoriesRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCategoriesRequest} returns this
*/
proto.currency_pair_endpoint.GetCategoriesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCategoriesRequest} returns this
 */
proto.currency_pair_endpoint.GetCategoriesRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCategoriesRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool order = 5;
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCategoriesRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.currency_pair_endpoint.GetCategoriesRequest} returns this
 */
proto.currency_pair_endpoint.GetCategoriesRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional google.protobuf.StringValue nextTo = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCategoriesRequest.prototype.getNextto = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCategoriesRequest} returns this
*/
proto.currency_pair_endpoint.GetCategoriesRequest.prototype.setNextto = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCategoriesRequest} returns this
 */
proto.currency_pair_endpoint.GetCategoriesRequest.prototype.clearNextto = function() {
  return this.setNextto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCategoriesRequest.prototype.hasNextto = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 numOfResults = 7;
 * @return {number}
 */
proto.currency_pair_endpoint.GetCategoriesRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.currency_pair_endpoint.GetCategoriesRequest} returns this
 */
proto.currency_pair_endpoint.GetCategoriesRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.PricePeriod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.PricePeriod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.PricePeriod.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    closesellprice: (f = msg.getClosesellprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    closebuyprice: (f = msg.getClosebuyprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    closeavgprice: (f = msg.getCloseavgprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    closetradingprice: (f = msg.getClosetradingprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    opensellprice: (f = msg.getOpensellprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    openbuyprice: (f = msg.getOpenbuyprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    openavgprice: (f = msg.getOpenavgprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    opentradingprice: (f = msg.getOpentradingprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    highestavgprice: (f = msg.getHighestavgprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lowsetavgprice: (f = msg.getLowsetavgprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    highestsellprice: (f = msg.getHighestsellprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lowsetsellprice: (f = msg.getLowsetsellprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    highestbuyprice: (f = msg.getHighestbuyprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lowsetbuyprice: (f = msg.getLowsetbuyprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    highesttradingprice: (f = msg.getHighesttradingprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lowsettradingprice: (f = msg.getLowsettradingprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    volumebase: (f = msg.getVolumebase()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    volumequote: (f = msg.getVolumequote()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.PricePeriod}
 */
proto.currency_pair_endpoint.PricePeriod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.PricePeriod;
  return proto.currency_pair_endpoint.PricePeriod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.PricePeriod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.PricePeriod}
 */
proto.currency_pair_endpoint.PricePeriod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setClosesellprice(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setClosebuyprice(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCloseavgprice(value);
      break;
    case 16:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setClosetradingprice(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOpensellprice(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOpenbuyprice(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOpenavgprice(value);
      break;
    case 17:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOpentradingprice(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setHighestavgprice(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLowsetavgprice(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setHighestsellprice(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLowsetsellprice(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setHighestbuyprice(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLowsetbuyprice(value);
      break;
    case 18:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setHighesttradingprice(value);
      break;
    case 19:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLowsettradingprice(value);
      break;
    case 14:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setVolumebase(value);
      break;
    case 15:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setVolumequote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.PricePeriod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.PricePeriod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.PricePeriod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getClosesellprice();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getClosebuyprice();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCloseavgprice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getClosetradingprice();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOpensellprice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOpenbuyprice();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOpenavgprice();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOpentradingprice();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getHighestavgprice();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLowsetavgprice();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getHighestsellprice();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLowsetsellprice();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getHighestbuyprice();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLowsetbuyprice();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getHighesttradingprice();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLowsettradingprice();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getVolumebase();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getVolumequote();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp date = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.getDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
*/
proto.currency_pair_endpoint.PricePeriod.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
 */
proto.currency_pair_endpoint.PricePeriod.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue closeSellPrice = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.getClosesellprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
*/
proto.currency_pair_endpoint.PricePeriod.prototype.setClosesellprice = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
 */
proto.currency_pair_endpoint.PricePeriod.prototype.clearClosesellprice = function() {
  return this.setClosesellprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.hasClosesellprice = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue closeBuyPrice = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.getClosebuyprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
*/
proto.currency_pair_endpoint.PricePeriod.prototype.setClosebuyprice = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
 */
proto.currency_pair_endpoint.PricePeriod.prototype.clearClosebuyprice = function() {
  return this.setClosebuyprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.hasClosebuyprice = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue closeAvgPrice = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.getCloseavgprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
*/
proto.currency_pair_endpoint.PricePeriod.prototype.setCloseavgprice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
 */
proto.currency_pair_endpoint.PricePeriod.prototype.clearCloseavgprice = function() {
  return this.setCloseavgprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.hasCloseavgprice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue closeTradingPrice = 16;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.getClosetradingprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 16));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
*/
proto.currency_pair_endpoint.PricePeriod.prototype.setClosetradingprice = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
 */
proto.currency_pair_endpoint.PricePeriod.prototype.clearClosetradingprice = function() {
  return this.setClosetradingprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.hasClosetradingprice = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.StringValue openSellPrice = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.getOpensellprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
*/
proto.currency_pair_endpoint.PricePeriod.prototype.setOpensellprice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
 */
proto.currency_pair_endpoint.PricePeriod.prototype.clearOpensellprice = function() {
  return this.setOpensellprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.hasOpensellprice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue openBuyPrice = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.getOpenbuyprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
*/
proto.currency_pair_endpoint.PricePeriod.prototype.setOpenbuyprice = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
 */
proto.currency_pair_endpoint.PricePeriod.prototype.clearOpenbuyprice = function() {
  return this.setOpenbuyprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.hasOpenbuyprice = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue openAvgPrice = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.getOpenavgprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
*/
proto.currency_pair_endpoint.PricePeriod.prototype.setOpenavgprice = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
 */
proto.currency_pair_endpoint.PricePeriod.prototype.clearOpenavgprice = function() {
  return this.setOpenavgprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.hasOpenavgprice = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue openTradingPrice = 17;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.getOpentradingprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 17));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
*/
proto.currency_pair_endpoint.PricePeriod.prototype.setOpentradingprice = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
 */
proto.currency_pair_endpoint.PricePeriod.prototype.clearOpentradingprice = function() {
  return this.setOpentradingprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.hasOpentradingprice = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional google.protobuf.StringValue highestAvgPrice = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.getHighestavgprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
*/
proto.currency_pair_endpoint.PricePeriod.prototype.setHighestavgprice = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
 */
proto.currency_pair_endpoint.PricePeriod.prototype.clearHighestavgprice = function() {
  return this.setHighestavgprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.hasHighestavgprice = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue lowsetAvgPrice = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.getLowsetavgprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
*/
proto.currency_pair_endpoint.PricePeriod.prototype.setLowsetavgprice = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
 */
proto.currency_pair_endpoint.PricePeriod.prototype.clearLowsetavgprice = function() {
  return this.setLowsetavgprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.hasLowsetavgprice = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue highestSellPrice = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.getHighestsellprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
*/
proto.currency_pair_endpoint.PricePeriod.prototype.setHighestsellprice = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
 */
proto.currency_pair_endpoint.PricePeriod.prototype.clearHighestsellprice = function() {
  return this.setHighestsellprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.hasHighestsellprice = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue lowsetSellPrice = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.getLowsetsellprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
*/
proto.currency_pair_endpoint.PricePeriod.prototype.setLowsetsellprice = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
 */
proto.currency_pair_endpoint.PricePeriod.prototype.clearLowsetsellprice = function() {
  return this.setLowsetsellprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.hasLowsetsellprice = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.StringValue highestBuyPrice = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.getHighestbuyprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
*/
proto.currency_pair_endpoint.PricePeriod.prototype.setHighestbuyprice = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
 */
proto.currency_pair_endpoint.PricePeriod.prototype.clearHighestbuyprice = function() {
  return this.setHighestbuyprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.hasHighestbuyprice = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.StringValue lowsetBuyPrice = 13;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.getLowsetbuyprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 13));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
*/
proto.currency_pair_endpoint.PricePeriod.prototype.setLowsetbuyprice = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
 */
proto.currency_pair_endpoint.PricePeriod.prototype.clearLowsetbuyprice = function() {
  return this.setLowsetbuyprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.hasLowsetbuyprice = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.StringValue highestTradingPrice = 18;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.getHighesttradingprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 18));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
*/
proto.currency_pair_endpoint.PricePeriod.prototype.setHighesttradingprice = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
 */
proto.currency_pair_endpoint.PricePeriod.prototype.clearHighesttradingprice = function() {
  return this.setHighesttradingprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.hasHighesttradingprice = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional google.protobuf.StringValue lowsetTradingPrice = 19;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.getLowsettradingprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 19));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
*/
proto.currency_pair_endpoint.PricePeriod.prototype.setLowsettradingprice = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
 */
proto.currency_pair_endpoint.PricePeriod.prototype.clearLowsettradingprice = function() {
  return this.setLowsettradingprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.hasLowsettradingprice = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.protobuf.StringValue volumeBase = 14;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.getVolumebase = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 14));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
*/
proto.currency_pair_endpoint.PricePeriod.prototype.setVolumebase = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
 */
proto.currency_pair_endpoint.PricePeriod.prototype.clearVolumebase = function() {
  return this.setVolumebase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.hasVolumebase = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.StringValue volumeQuote = 15;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.getVolumequote = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 15));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
*/
proto.currency_pair_endpoint.PricePeriod.prototype.setVolumequote = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.PricePeriod} returns this
 */
proto.currency_pair_endpoint.PricePeriod.prototype.clearVolumequote = function() {
  return this.setVolumequote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.PricePeriod.prototype.hasVolumequote = function() {
  return jspb.Message.getField(this, 15) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.currency_pair_endpoint.GetChartReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.GetChartReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.GetChartReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.GetChartReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetChartReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportList: jspb.Message.toObjectList(msg.getReportList(),
    proto.currency_pair_endpoint.PricePeriod.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.GetChartReplay}
 */
proto.currency_pair_endpoint.GetChartReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.GetChartReplay;
  return proto.currency_pair_endpoint.GetChartReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.GetChartReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.GetChartReplay}
 */
proto.currency_pair_endpoint.GetChartReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_pair_endpoint.PricePeriod;
      reader.readMessage(value,proto.currency_pair_endpoint.PricePeriod.deserializeBinaryFromReader);
      msg.addReport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.GetChartReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.GetChartReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.GetChartReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetChartReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.currency_pair_endpoint.PricePeriod.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PricePeriod report = 1;
 * @return {!Array<!proto.currency_pair_endpoint.PricePeriod>}
 */
proto.currency_pair_endpoint.GetChartReplay.prototype.getReportList = function() {
  return /** @type{!Array<!proto.currency_pair_endpoint.PricePeriod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.currency_pair_endpoint.PricePeriod, 1));
};


/**
 * @param {!Array<!proto.currency_pair_endpoint.PricePeriod>} value
 * @return {!proto.currency_pair_endpoint.GetChartReplay} returns this
*/
proto.currency_pair_endpoint.GetChartReplay.prototype.setReportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.currency_pair_endpoint.PricePeriod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.currency_pair_endpoint.PricePeriod}
 */
proto.currency_pair_endpoint.GetChartReplay.prototype.addReport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.currency_pair_endpoint.PricePeriod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.currency_pair_endpoint.GetChartReplay} returns this
 */
proto.currency_pair_endpoint.GetChartReplay.prototype.clearReportList = function() {
  return this.setReportList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.GetCurrencyPairReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    base: (f = msg.getBase()) && Protos_common_pb.CurrencyResponse.toObject(includeInstance, f),
    quote: (f = msg.getQuote()) && Protos_common_pb.CurrencyResponse.toObject(includeInstance, f),
    sellprice: (f = msg.getSellprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    buyprice: (f = msg.getBuyprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    tradingprice: (f = msg.getTradingprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    priceupdate: (f = msg.getPriceupdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    strictsellprice: (f = msg.getStrictsellprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    strictbuyprice: (f = msg.getStrictbuyprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    sellpriceupdate: (f = msg.getSellpriceupdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    buypriceupdate: (f = msg.getBuypriceupdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    category: (f = msg.getCategory()) && Protos_common_pb.CategoryResponse.toObject(includeInstance, f),
    iconsource: (f = msg.getIconsource()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.GetCurrencyPairReplay;
  return proto.currency_pair_endpoint.GetCurrencyPairReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 2:
      var value = new Protos_common_pb.CurrencyResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyResponse.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 3:
      var value = new Protos_common_pb.CurrencyResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyResponse.deserializeBinaryFromReader);
      msg.setQuote(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSellprice(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBuyprice(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTradingprice(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPriceupdate(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setStrictsellprice(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setStrictbuyprice(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSellpriceupdate(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBuypriceupdate(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 15:
      var value = new Protos_common_pb.CategoryResponse;
      reader.readMessage(value,Protos_common_pb.CategoryResponse.deserializeBinaryFromReader);
      msg.setCategory(value);
      break;
    case 17:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setIconsource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.GetCurrencyPairReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getQuote();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getSellprice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBuyprice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTradingprice();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPriceupdate();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStrictsellprice();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStrictbuyprice();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSellpriceupdate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBuypriceupdate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCategory();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      Protos_common_pb.CategoryResponse.serializeBinaryToWriter
    );
  }
  f = message.getIconsource();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional common.CurrencyResponse base = 2;
 * @return {?proto.common.CurrencyResponse}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getBase = function() {
  return /** @type{?proto.common.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyResponse, 2));
};


/**
 * @param {?proto.common.CurrencyResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasBase = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.CurrencyResponse quote = 3;
 * @return {?proto.common.CurrencyResponse}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getQuote = function() {
  return /** @type{?proto.common.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyResponse, 3));
};


/**
 * @param {?proto.common.CurrencyResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setQuote = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearQuote = function() {
  return this.setQuote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasQuote = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue sellPrice = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getSellprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setSellprice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearSellprice = function() {
  return this.setSellprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasSellprice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue buyPrice = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getBuyprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setBuyprice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearBuyprice = function() {
  return this.setBuyprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasBuyprice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue tradingPrice = 13;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getTradingprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 13));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setTradingprice = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearTradingprice = function() {
  return this.setTradingprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasTradingprice = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.Timestamp priceUpdate = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getPriceupdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setPriceupdate = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearPriceupdate = function() {
  return this.setPriceupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasPriceupdate = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.StringValue strictSellPrice = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getStrictsellprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setStrictsellprice = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearStrictsellprice = function() {
  return this.setStrictsellprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasStrictsellprice = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue strictBuyPrice = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getStrictbuyprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setStrictbuyprice = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearStrictbuyprice = function() {
  return this.setStrictbuyprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasStrictbuyprice = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp sellPriceUpdate = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getSellpriceupdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setSellpriceupdate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearSellpriceupdate = function() {
  return this.setSellpriceupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasSellpriceupdate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp buyPriceUpdate = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getBuypriceupdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setBuypriceupdate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearBuypriceupdate = function() {
  return this.setBuypriceupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasBuypriceupdate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional common.CategoryResponse category = 15;
 * @return {?proto.common.CategoryResponse}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getCategory = function() {
  return /** @type{?proto.common.CategoryResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CategoryResponse, 15));
};


/**
 * @param {?proto.common.CategoryResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setCategory = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearCategory = function() {
  return this.setCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasCategory = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.StringValue iconSource = 17;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.getIconsource = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 17));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.setIconsource = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.clearIconsource = function() {
  return this.setIconsource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairReplay.prototype.hasIconsource = function() {
  return jspb.Message.getField(this, 17) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.CurrencyPair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.CurrencyPair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.CurrencyPair.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    base: (f = msg.getBase()) && Protos_common_pb.CurrencyResponse.toObject(includeInstance, f),
    quote: (f = msg.getQuote()) && Protos_common_pb.CurrencyResponse.toObject(includeInstance, f),
    sellprice: (f = msg.getSellprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    buyprice: (f = msg.getBuyprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    strictsellprice: (f = msg.getStrictsellprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    strictbuyprice: (f = msg.getStrictbuyprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    sellpriceupdate: (f = msg.getSellpriceupdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    buypriceupdate: (f = msg.getBuypriceupdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    tradingprice: (f = msg.getTradingprice()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    priceupdate: (f = msg.getPriceupdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    category: (f = msg.getCategory()) && Protos_common_pb.CategoryResponse.toObject(includeInstance, f),
    iconsource: (f = msg.getIconsource()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.CurrencyPair}
 */
proto.currency_pair_endpoint.CurrencyPair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.CurrencyPair;
  return proto.currency_pair_endpoint.CurrencyPair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.CurrencyPair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.CurrencyPair}
 */
proto.currency_pair_endpoint.CurrencyPair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 2:
      var value = new Protos_common_pb.CurrencyResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyResponse.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 3:
      var value = new Protos_common_pb.CurrencyResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyResponse.deserializeBinaryFromReader);
      msg.setQuote(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSellprice(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBuyprice(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setStrictsellprice(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setStrictbuyprice(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSellpriceupdate(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBuypriceupdate(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTradingprice(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPriceupdate(value);
      break;
    case 15:
      var value = new Protos_common_pb.CategoryResponse;
      reader.readMessage(value,Protos_common_pb.CategoryResponse.deserializeBinaryFromReader);
      msg.setCategory(value);
      break;
    case 17:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setIconsource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.CurrencyPair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.CurrencyPair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.CurrencyPair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getQuote();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getSellprice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBuyprice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStrictsellprice();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStrictbuyprice();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSellpriceupdate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBuypriceupdate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTradingprice();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPriceupdate();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCategory();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      Protos_common_pb.CategoryResponse.serializeBinaryToWriter
    );
  }
  f = message.getIconsource();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasName = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional common.CurrencyResponse base = 2;
 * @return {?proto.common.CurrencyResponse}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getBase = function() {
  return /** @type{?proto.common.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyResponse, 2));
};


/**
 * @param {?proto.common.CurrencyResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasBase = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.CurrencyResponse quote = 3;
 * @return {?proto.common.CurrencyResponse}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getQuote = function() {
  return /** @type{?proto.common.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyResponse, 3));
};


/**
 * @param {?proto.common.CurrencyResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setQuote = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearQuote = function() {
  return this.setQuote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasQuote = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue sellPrice = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getSellprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setSellprice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearSellprice = function() {
  return this.setSellprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasSellprice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue buyPrice = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getBuyprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setBuyprice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearBuyprice = function() {
  return this.setBuyprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasBuyprice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue strictSellPrice = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getStrictsellprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setStrictsellprice = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearStrictsellprice = function() {
  return this.setStrictsellprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasStrictsellprice = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue strictBuyPrice = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getStrictbuyprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setStrictbuyprice = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearStrictbuyprice = function() {
  return this.setStrictbuyprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasStrictbuyprice = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp sellPriceUpdate = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getSellpriceupdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setSellpriceupdate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearSellpriceupdate = function() {
  return this.setSellpriceupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasSellpriceupdate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp buyPriceUpdate = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getBuypriceupdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setBuypriceupdate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearBuypriceupdate = function() {
  return this.setBuypriceupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasBuypriceupdate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.StringValue tradingPrice = 13;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getTradingprice = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 13));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setTradingprice = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearTradingprice = function() {
  return this.setTradingprice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasTradingprice = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.Timestamp priceUpdate = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getPriceupdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setPriceupdate = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearPriceupdate = function() {
  return this.setPriceupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasPriceupdate = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional common.CategoryResponse category = 15;
 * @return {?proto.common.CategoryResponse}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getCategory = function() {
  return /** @type{?proto.common.CategoryResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CategoryResponse, 15));
};


/**
 * @param {?proto.common.CategoryResponse|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setCategory = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearCategory = function() {
  return this.setCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasCategory = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.StringValue iconSource = 17;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.getIconsource = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 17));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
*/
proto.currency_pair_endpoint.CurrencyPair.prototype.setIconsource = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.CurrencyPair} returns this
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.clearIconsource = function() {
  return this.setIconsource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.CurrencyPair.prototype.hasIconsource = function() {
  return jspb.Message.getField(this, 17) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.Category.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.Category.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.Category} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.Category.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    namear: (f = msg.getNamear()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    nameen: (f = msg.getNameen()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    rank: (f = msg.getRank()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.Category}
 */
proto.currency_pair_endpoint.Category.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.Category;
  return proto.currency_pair_endpoint.Category.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.Category} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.Category}
 */
proto.currency_pair_endpoint.Category.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNamear(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNameen(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setRank(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.Category.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.Category.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.Category} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.Category.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNamear();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNameen();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRank();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.currency_pair_endpoint.Category.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.currency_pair_endpoint.Category} returns this
 */
proto.currency_pair_endpoint.Category.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue nameAr = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.Category.prototype.getNamear = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.Category} returns this
*/
proto.currency_pair_endpoint.Category.prototype.setNamear = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.Category} returns this
 */
proto.currency_pair_endpoint.Category.prototype.clearNamear = function() {
  return this.setNamear(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.Category.prototype.hasNamear = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue nameEn = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.currency_pair_endpoint.Category.prototype.getNameen = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.currency_pair_endpoint.Category} returns this
*/
proto.currency_pair_endpoint.Category.prototype.setNameen = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.Category} returns this
 */
proto.currency_pair_endpoint.Category.prototype.clearNameen = function() {
  return this.setNameen(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.Category.prototype.hasNameen = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value rank = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.currency_pair_endpoint.Category.prototype.getRank = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.currency_pair_endpoint.Category} returns this
*/
proto.currency_pair_endpoint.Category.prototype.setRank = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.Category} returns this
 */
proto.currency_pair_endpoint.Category.prototype.clearRank = function() {
  return this.setRank(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.Category.prototype.hasRank = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.currency_pair_endpoint.GetCurrencyPairsReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.GetCurrencyPairsReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.GetCurrencyPairsReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairsReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairsReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    currencypairsList: jspb.Message.toObjectList(msg.getCurrencypairsList(),
    proto.currency_pair_endpoint.CurrencyPair.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsReplay}
 */
proto.currency_pair_endpoint.GetCurrencyPairsReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.GetCurrencyPairsReplay;
  return proto.currency_pair_endpoint.GetCurrencyPairsReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairsReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsReplay}
 */
proto.currency_pair_endpoint.GetCurrencyPairsReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_pair_endpoint.CurrencyPair;
      reader.readMessage(value,proto.currency_pair_endpoint.CurrencyPair.deserializeBinaryFromReader);
      msg.addCurrencypairs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.GetCurrencyPairsReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.GetCurrencyPairsReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairsReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairsReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencypairsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.currency_pair_endpoint.CurrencyPair.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CurrencyPair currencyPairs = 1;
 * @return {!Array<!proto.currency_pair_endpoint.CurrencyPair>}
 */
proto.currency_pair_endpoint.GetCurrencyPairsReplay.prototype.getCurrencypairsList = function() {
  return /** @type{!Array<!proto.currency_pair_endpoint.CurrencyPair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.currency_pair_endpoint.CurrencyPair, 1));
};


/**
 * @param {!Array<!proto.currency_pair_endpoint.CurrencyPair>} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsReplay} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairsReplay.prototype.setCurrencypairsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.currency_pair_endpoint.CurrencyPair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.currency_pair_endpoint.CurrencyPair}
 */
proto.currency_pair_endpoint.GetCurrencyPairsReplay.prototype.addCurrencypairs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.currency_pair_endpoint.CurrencyPair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsReplay} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairsReplay.prototype.clearCurrencypairsList = function() {
  return this.setCurrencypairsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.currency_pair_endpoint.GetCategoriesReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.GetCategoriesReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.GetCategoriesReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.GetCategoriesReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCategoriesReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoriesList: jspb.Message.toObjectList(msg.getCategoriesList(),
    proto.currency_pair_endpoint.Category.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.GetCategoriesReplay}
 */
proto.currency_pair_endpoint.GetCategoriesReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.GetCategoriesReplay;
  return proto.currency_pair_endpoint.GetCategoriesReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.GetCategoriesReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.GetCategoriesReplay}
 */
proto.currency_pair_endpoint.GetCategoriesReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_pair_endpoint.Category;
      reader.readMessage(value,proto.currency_pair_endpoint.Category.deserializeBinaryFromReader);
      msg.addCategories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.GetCategoriesReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.GetCategoriesReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.GetCategoriesReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCategoriesReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.currency_pair_endpoint.Category.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Category categories = 1;
 * @return {!Array<!proto.currency_pair_endpoint.Category>}
 */
proto.currency_pair_endpoint.GetCategoriesReplay.prototype.getCategoriesList = function() {
  return /** @type{!Array<!proto.currency_pair_endpoint.Category>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.currency_pair_endpoint.Category, 1));
};


/**
 * @param {!Array<!proto.currency_pair_endpoint.Category>} value
 * @return {!proto.currency_pair_endpoint.GetCategoriesReplay} returns this
*/
proto.currency_pair_endpoint.GetCategoriesReplay.prototype.setCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.currency_pair_endpoint.Category=} opt_value
 * @param {number=} opt_index
 * @return {!proto.currency_pair_endpoint.Category}
 */
proto.currency_pair_endpoint.GetCategoriesReplay.prototype.addCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.currency_pair_endpoint.Category, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.currency_pair_endpoint.GetCategoriesReplay} returns this
 */
proto.currency_pair_endpoint.GetCategoriesReplay.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.currency_pair_endpoint.GetCurrencyPairsResponse.ResultCase}
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse.prototype.getResultCase = function() {
  return /** @type {proto.currency_pair_endpoint.GetCurrencyPairsResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.currency_pair_endpoint.GetCurrencyPairsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.GetCurrencyPairsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.currency_pair_endpoint.GetCurrencyPairsReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsResponse}
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.GetCurrencyPairsResponse;
  return proto.currency_pair_endpoint.GetCurrencyPairsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsResponse}
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_pair_endpoint.GetCurrencyPairsReplay;
      reader.readMessage(value,proto.currency_pair_endpoint.GetCurrencyPairsReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.GetCurrencyPairsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.currency_pair_endpoint.GetCurrencyPairsReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetCurrencyPairsReplay success = 1;
 * @return {?proto.currency_pair_endpoint.GetCurrencyPairsReplay}
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse.prototype.getSuccess = function() {
  return /** @type{?proto.currency_pair_endpoint.GetCurrencyPairsReplay} */ (
    jspb.Message.getWrapperField(this, proto.currency_pair_endpoint.GetCurrencyPairsReplay, 1));
};


/**
 * @param {?proto.currency_pair_endpoint.GetCurrencyPairsReplay|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsResponse} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.currency_pair_endpoint.GetCurrencyPairsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairsResponse} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairsResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.currency_pair_endpoint.GetCurrencyPairResponse.ResultCase}
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse.prototype.getResultCase = function() {
  return /** @type {proto.currency_pair_endpoint.GetCurrencyPairResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.currency_pair_endpoint.GetCurrencyPairResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.GetCurrencyPairResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.currency_pair_endpoint.GetCurrencyPairReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairResponse}
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.GetCurrencyPairResponse;
  return proto.currency_pair_endpoint.GetCurrencyPairResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairResponse}
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_pair_endpoint.GetCurrencyPairReplay;
      reader.readMessage(value,proto.currency_pair_endpoint.GetCurrencyPairReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.GetCurrencyPairResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.GetCurrencyPairResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.currency_pair_endpoint.GetCurrencyPairReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetCurrencyPairReplay success = 1;
 * @return {?proto.currency_pair_endpoint.GetCurrencyPairReplay}
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse.prototype.getSuccess = function() {
  return /** @type{?proto.currency_pair_endpoint.GetCurrencyPairReplay} */ (
    jspb.Message.getWrapperField(this, proto.currency_pair_endpoint.GetCurrencyPairReplay, 1));
};


/**
 * @param {?proto.currency_pair_endpoint.GetCurrencyPairReplay|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairResponse} returns this
*/
proto.currency_pair_endpoint.GetCurrencyPairResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.currency_pair_endpoint.GetCurrencyPairResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCurrencyPairResponse} returns this
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCurrencyPairResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.currency_pair_endpoint.GetCategoriesResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.currency_pair_endpoint.GetCategoriesResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.currency_pair_endpoint.GetCategoriesResponse.ResultCase}
 */
proto.currency_pair_endpoint.GetCategoriesResponse.prototype.getResultCase = function() {
  return /** @type {proto.currency_pair_endpoint.GetCategoriesResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.currency_pair_endpoint.GetCategoriesResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.GetCategoriesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.GetCategoriesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.GetCategoriesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCategoriesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.currency_pair_endpoint.GetCategoriesReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.GetCategoriesResponse}
 */
proto.currency_pair_endpoint.GetCategoriesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.GetCategoriesResponse;
  return proto.currency_pair_endpoint.GetCategoriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.GetCategoriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.GetCategoriesResponse}
 */
proto.currency_pair_endpoint.GetCategoriesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_pair_endpoint.GetCategoriesReplay;
      reader.readMessage(value,proto.currency_pair_endpoint.GetCategoriesReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.GetCategoriesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.GetCategoriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.GetCategoriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetCategoriesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.currency_pair_endpoint.GetCategoriesReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetCategoriesReplay success = 1;
 * @return {?proto.currency_pair_endpoint.GetCategoriesReplay}
 */
proto.currency_pair_endpoint.GetCategoriesResponse.prototype.getSuccess = function() {
  return /** @type{?proto.currency_pair_endpoint.GetCategoriesReplay} */ (
    jspb.Message.getWrapperField(this, proto.currency_pair_endpoint.GetCategoriesReplay, 1));
};


/**
 * @param {?proto.currency_pair_endpoint.GetCategoriesReplay|undefined} value
 * @return {!proto.currency_pair_endpoint.GetCategoriesResponse} returns this
*/
proto.currency_pair_endpoint.GetCategoriesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.currency_pair_endpoint.GetCategoriesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetCategoriesResponse} returns this
 */
proto.currency_pair_endpoint.GetCategoriesResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetCategoriesResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.currency_pair_endpoint.GetChartResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.currency_pair_endpoint.GetChartResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.currency_pair_endpoint.GetChartResponse.ResultCase}
 */
proto.currency_pair_endpoint.GetChartResponse.prototype.getResultCase = function() {
  return /** @type {proto.currency_pair_endpoint.GetChartResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.currency_pair_endpoint.GetChartResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.currency_pair_endpoint.GetChartResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.currency_pair_endpoint.GetChartResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.currency_pair_endpoint.GetChartResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetChartResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.currency_pair_endpoint.GetChartReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.currency_pair_endpoint.GetChartResponse}
 */
proto.currency_pair_endpoint.GetChartResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.currency_pair_endpoint.GetChartResponse;
  return proto.currency_pair_endpoint.GetChartResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.currency_pair_endpoint.GetChartResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.currency_pair_endpoint.GetChartResponse}
 */
proto.currency_pair_endpoint.GetChartResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.currency_pair_endpoint.GetChartReplay;
      reader.readMessage(value,proto.currency_pair_endpoint.GetChartReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.currency_pair_endpoint.GetChartResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.currency_pair_endpoint.GetChartResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.currency_pair_endpoint.GetChartResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.currency_pair_endpoint.GetChartResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.currency_pair_endpoint.GetChartReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetChartReplay success = 1;
 * @return {?proto.currency_pair_endpoint.GetChartReplay}
 */
proto.currency_pair_endpoint.GetChartResponse.prototype.getSuccess = function() {
  return /** @type{?proto.currency_pair_endpoint.GetChartReplay} */ (
    jspb.Message.getWrapperField(this, proto.currency_pair_endpoint.GetChartReplay, 1));
};


/**
 * @param {?proto.currency_pair_endpoint.GetChartReplay|undefined} value
 * @return {!proto.currency_pair_endpoint.GetChartResponse} returns this
*/
proto.currency_pair_endpoint.GetChartResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.currency_pair_endpoint.GetChartResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.currency_pair_endpoint.GetChartResponse} returns this
 */
proto.currency_pair_endpoint.GetChartResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.currency_pair_endpoint.GetChartResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.currency_pair_endpoint);
